/* Render a tag cloud
 *
 */

import React from 'react'

import styles from "./Tagcloud.module.styl"

class Tagcloud extends React.Component {
  render() {
    return (
      <ul className={ styles.tags }>
        {
          this.props.tags.map((t) => {
            return (
              <li key={ t }>{ t }</li>
            )
          })
        }
      </ul>
    )
  }
}

export default Tagcloud
