/* Clients
 *
 */

import React from 'react'

import Layout from "../components/Layout"
import Tagcloud from "../components/Tagcloud"
import SEO from '../components/seo'

import bsdex from "../../content/assets/clients/bsdex.png"
import styles from "./clients.module.styl"

const title = 'FELD Berlin. Work and clients'
const description = `
  FELD can help your company to successfully implement your data science
  projects. A special focus is on robust custom machine learning systems that
  provide business value in practice, not just in theory. You can also benefit
  from investing into your data foundations: you will enable more successful
  data science projects in the future.
`

class Clients extends React.Component {
  render() {
    return (
      <Layout location={ this.props.location } footer={ "newsletter" }>
        <SEO title={ title } description={ description } />
        <div className={ styles.clients }>
          <h1>Success Stories</h1>
          <p>{ description }</p>
          <div className={ styles.client }>
            <div className={ styles.main }>
              <h2>Börse Stuttgart Digital Exchange</h2>
              <p>
                Börse Stuttgart Digital Exchange is a group company of the
                second largest stock exchange in Germany, Börse Stuttgart.
                BSDEX is Germany's first regulated market for digital assets like
                Bitcoin. FELD was commissioned to design and build the data
                platform for this company founded in 2019.
              </p>
              <p>
                The Börse Stuttgart Digital Exchange commissioned FELD to
                design and build a data platform for their new <a
                href="https://bsdex.de/en/">digital assets trading platform</a>.
                BSDEX wanted a system which would fulfill regulatory
                requirements on the data, and would impose a structure on the
                growth of the data landscape at the company.
              </p>
              <p>
                Through a series of interviews and workshops, FELD
                assessed the current situation and developed a shared vision
                and mission for the new platform. FELD developed an
                architecture and roadmap for the system, and then
                completed the implementation of a prototypal system,
                together with stakeholders at the BSDEX. After proving the
                system as a backbone for reporting, it was rolled out for
                general usage on AWS.
              </p>
              <p>
                The resulting system has helped BSDEX to grow their business
                intelligence operations, and with the full data stack of AWS
                at their disposal, reporting in Quicksight enabled a full
                range of top level business metrics to be distributed to the
                whole company on a daily basis, all on the basis of the new
                data platform. Reporting is also served by the
                data platform, and BSDEX now has a solid foundation for their
                future data needs.
              </p>
            </div>
            <div className={ styles.meta }>
              <h4>Our Contributions</h4>
              <Tagcloud tags={ [
                'amazon glue',
                'athena',
                'aws',
                'business intelligence',
                'dashboards',
                'data architecture',
                'data catalog',
                'data governance',
                'data mesh',
                'data platform',
                'gdpr',
                'metrics',
                'quicksight',
                'parquet',
                'privacy',
                'reporting',
                's3',
              ] } />
              <h4>How we worked</h4>
              <p>
                FELD interfaced directly with the managing directors and CTO, the
                leadership team and tech leads and architects of all teams at
                BSDEX, as well as key stakeholders at Börse Stuttgart. Through
                workshops, internal advocacy, design meetings and one on one pairing
                sessions, the architecture was elaborated and implemented together with
                technical teams at BSDEX.
              </p>
              <a href="https://bsdex.de/en" alt="bsdex.de">
                <img src={ bsdex } alt="bsdex" />
              </a>
              <p>
                Börse Stuttgart Digital Exchange is Germany's first regulated
                market for digital assets like Bitcoin, and is a group company
                of Börse Stuttgart, the second largest stock exchange in Germany.
              </p>
            </div>
          </div>
       </div>
      </Layout>
    )
  }
}

export default Clients
